<template>
  <div class="layout-wrapper layout-overlay" @click="onDocumentClick">
    <div class="header background-theme-color">
      <Logo :maxHeight="50"/>
      <div>
        <LoginLanguageSelector
            @languageMenubuttonClick="onLanguageMenuButtonClick"
            :languageMenuActive="languageMenuActive"/>
      </div>
    </div>
    <div class="layout-content">
      <div class="layout-content-container">
        <div class="p-grid">
          <div class="p-col-12">
            <div class="card" :class="{'p-pl-3 p-pr-3':$store.state.mobileLayout}">
              <Toolbar class="p-mb-4">
                <template #start>
                  <div style="font-size: 1.4em; color: inherit">{{ $t('Price request form') }}</div>
                </template>
                <template #end>
                  <div><span class="warning-color">* </span>{{ $t('Fields are required') }}</div>
                </template>
              </Toolbar>
              <PriceRequestForm/>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
<!--      <footer class="layout-footer">-->
<!--        <span class="footer-text-left">© {{ getYear }} <b><a href="https://virtualgarage.app/" target="_blank">VirtualGarage.app</a></b>. {{ $t('All Rights Reserved') }}</span>-->
<!--      </footer>-->
  </div>
</template>

<script>
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import LoginLanguageSelector from "@/components/LoginLanguageSelector";
import calculateMixins from "@/mixins/calculateMixins";
import PriceRequestForm from "@/pages/price_request/PriceRequestForm";
import settings from "@/settings";
import AppFooter from "@/AppFooter";
import Logo from "@/components/Logo";

export default {
  mixins: [ httpMixins, formatMixins, calculateMixins ],
  emits: ['update-offer-data'],
  components: { LoginLanguageSelector, PriceRequestForm, AppFooter, Logo },
  props: {
    offerData: Object,
    useAppointmentBooking: {
      type: Boolean,
      default: false
    },
    nonBookingDays: Array,
    appointmentFirstDate: Number,
    appointmentLastDate: Number,
    bookingHours: Object,
    workingHours: Object,
    bookingStep: Number,
  },
  data() {
    return {
      languageMenuActive: false,
      user: {
        settings: {
          ripple: true,
          inputStyle: 'outlined',
          layoutMode: 'horizontal',
          schema: 'light',
          theme: 'blue'
        }
      },
    }
  },
  watch: {
    '$route'() {
      if (this.$route.path === '/login' || this.$route.path !== '/price-request') {
        return false
      }
    },
    '$i18n.locale'() {
      this.changeTitle()
      // this.translatePrimevueComponents()
    },
  },
  mounted() {
    const storageLang = localStorage.getItem('lang')
    const routeLang = this.$route.params.lang

    let selectedLang = settings.defaultLanguage

    if (storageLang) {
      selectedLang = storageLang
    } else if (routeLang) {
      selectedLang = routeLang
    }
    const foundLangFromSettings = settings.languages.find(lang => lang.value === selectedLang)
    if (!foundLangFromSettings) {
      selectedLang = settings.defaultLanguage
    }

    this.$i18n.locale = selectedLang

    this.checkWindowSize()

    if (this.$i18n.locale === settings.defaultLanguage) {
      this.changeTitle()
    }

    window.addEventListener('resize', this.checkWindowSize)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    checkWindowSize() {
      if (window.innerWidth <= 1024) {
        // this.actualLayoutMode = 'static'
        this.$store.commit('changeLayoutMode', 'static')

        this.$store.commit('toggleTabletLayout', true)
        if (window.innerWidth <= 640) {
          this.$store.commit('toggleMobileLayout', true)
        } else {
          this.$store.commit('toggleMobileLayout', false)
        }
      } else {
        this.$store.commit('changeLayoutMode', this.user.settings.layoutMode)
        // this.actualLayoutMode = this.user.settings.layoutMode

        this.$store.commit('toggleTabletLayout', false)
        this.$store.commit('toggleMobileLayout', false)
      }
      // this.logoIsSmall = window.innerWidth <= 740
    },
    changeTitle() {
      const appName = settings.appName
      // const defaultTitle = appName
      // if (this.$route.meta.title) {
      //   document.title = this.$t(this.$route.meta.title) + ' | ' + appName
      // } else {
      //   document.title = defaultTitle
      // }
      document.title = this.$t('Price request') + ' | ' + appName
    },
    onLanguageMenuButtonClick() {
      this.languageMenuButtonClick = true
      this.languageMenuActive = !this.languageMenuActive
    },
    onDocumentClick() {
      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }
      this.languageMenuButtonClick = false
    },
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
.header {
  z-index: 10;
  position: fixed;
  top: 0;
  padding: 8px 16px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px 0px transparentize(#000, 0.7);
  .logo-wrapper {
    margin-right: 10px;
    .logo-img {
      display: block;
      max-height: 50px;
      max-width: 280px;
      width: 100%;
      height: 100%;
    }
  }
}

//.layout-content-container {
//  padding-top: 30px!important;
//}

.layout-content {
  padding-top:54px!important;
}

</style>