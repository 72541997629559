<template>
  <Toast/>
<!--  <div class="price-request-form-body" :class="{'price-request-form-body&#45;&#45;offer-created':showOfferCreatedBlock}" @click="onDocumentClick">-->
<!--  <div class="price-request-form-body" :class="{'price-request-form-body&#45;&#45;offer-created':showOfferCreatedBlock}" @click="onDocumentClick">-->
  <div class="price-request-form-body" @click="onDocumentClick">
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else-if="!showOfferCreatedBlock" class="">
<!--    <div v-if="1 === 3">-->
<!--      <Toolbar class="p-mb-4">-->
<!--        <template #start>-->
<!--          &lt;!&ndash;                  <div style="font-size: 1.4em; color: inherit">Price request TEST: {{ $t('Offer') }}</div>&ndash;&gt;-->
<!--          <div style="font-size: 1.4em; color: inherit">Price request</div>-->
<!--        </template>-->
<!--      </Toolbar>-->
<!--      <div class="" style="display: grid; grid-gap: 20px; grid-template-row: 1fr 1fr;">-->
      <div :class="{'grid' : !$store.state.tabletLayout && !$store.state.mobileLayout}">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 fieldset" :class="{'p-p-3' : !$store.state.mobileLayout}">
            <div class="price-request-form__legend fieldset-header">{{ $t('Vehicle data') }}</div>
            <div class="p-grid">
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
                  <CustomDropdown id="form-car-make"
                                      class="p-inputtext-lg"
                                      :disabled="!carMakes"
                                      :class="{'p-invalid' : submitted && !selectedMake}"
                                      v-model="selectedMake"
                                      :loading="makesDataIsLoading"
                                      dataKey="id"
                                      :isColorCustom="true"
                                      :options="carMakes"
                                      optionLabel="name"
                                      @change="changeCarMake"
                                      :filter="true"
                                      :showClear="true">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.name }}</span>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                  </CustomDropdown>
                  <label for="form-car-make">{{ $t('Make') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && !selectedMake">{{ $t('Required field') }}</small>
              </div>
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
                  <CustomDropdown id="form-car-model"
                                      class="p-inputtext-lg"
                                      :disabled="!carModels"
                                      :class="{'p-invalid' : submitted && !selectedModel}"
                                      v-model="selectedModel"
                                      :loading="modelsDataIsLoading"
                                      dataKey="id"
                                      @filter="clearModelSearchData = false"
                                      :clearSearchData="clearModelSearchData"
                                      :isColorCustom="true"
                                      :options="carModels"
                                      optionLabel="name"
                                      :filter="true"
                                      :showClear="true">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.name }}</span>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                  </CustomDropdown>
<!--                  <label for="form-car-model">{{ selectedMake ? $t('Model') : ''}}<span v-show="selectedMake" class="warning-color">*</span></label>-->
                  <label for="form-car-model">{{ $t('Model') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && !selectedModel">{{ $t('Required field') }}</small>
              </div>
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
<!--                  @keypress="plateNumberOnKeyPress"-->
<!--                  @input="plateNumberOnInput"-->
                  <InputText id="form-plate-number"
                             @change="changePlateNumber"
                             class="p-inputtext-lg price-request-form__input"
                             :class="{'p-invalid' : submitted && (!plateNumber || plateNumber?.length > maxPlateNumberLength)}"
                             v-model.trim="plateNumber"
                             autocomplete="off"/>
                  <label for="form-plate-number">{{ $t('Plate number') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && !plateNumber">{{ $t('Required field') }}</small>
                <small class="p-invalid" v-else-if="submitted && plateNumber.length > maxPlateNumberLength">{{ $t('Maximum length') }}: {{ maxPlateNumberLength }}</small>
              </div>
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
<!--                  :min="minCarYear"-->
<!--                  <InputNumber :locale="computedNumberInputLocale"-->
<!--                               id="form-car-year"-->
<!--                               v-model="itemData.year"-->
<!--                               :useGrouping="false"-->
<!--                               :min="new Date().getFullYear() - 80"-->
<!--                               :max="new Date().getFullYear()"-->
<!--                               showButtons-->
<!--                               autocomplete="off"/>-->
<!--                  <InputNumber id="form-car-year"-->
<!--                               class="p-inputtext-lg"-->
<!--                               :inputClass="{'p-invalid' : submitted && !year}"-->
<!--                               v-model="year"-->
<!--                               :min="minCarYear"-->
<!--                               :max="new Date().getFullYear()"-->
<!--                               :useGrouping="false"-->
<!--                               showButtons-->
<!--                               autocomplete="off"/>-->
                  <InputText id="form-car-year"
                             class=" phone-number p-inputtext-lg price-request-form__input"
                             :class="{'p-invalid' : submitted && !year}"
                             v-model="year"
                             type="number"
                             @keypress="numberInputOnKeyPress"
                             @paste="numberOnPaste"
                             @blur="checkCarYear"
                             autocomplete="new-password"/>
                  <label for="form-car-year">{{ $t('Production year') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && !year">{{ $t('Required field') }}</small>
                <small class="p-invalid" v-else-if="submitted && year < minCarYear">{{ $t('Minimum value') }}: {{ minCarYear }}</small>
                <small class="p-invalid" v-else-if="submitted && year > maxCarYear">{{ $t('Maximum value') }}: {{ maxCarYear }}</small>
              </div>
<!--              <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-0">-->
<!--                <div class="p-float-label">-->
<!--                  <InputText id="form-car-vin-code"-->
<!--              @change="changeVin"-->
<!--                             class="p-inputtext-lg price-request-form__input"-->
<!--                             v-model.trim="vinCode"-->
<!--                             autocomplete="off"/>-->
<!--                  <label for="form-car-vin-code">VIN code</label>-->
<!--                </div>-->
  <!--              <small class="p-invalid" v-if="submitted && !vin">{{ $t('Required field') }}</small>-->
<!--              </div>-->
            </div>
        </div>
<!--      </div>-->
<!--      <div class="p-fluid">-->
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 fieldset" :class="{'p-p-3' : !$store.state.mobileLayout}">
          <div class="fieldset-header">
            <div class="price-request-form__legend p-mr-4">{{ $t('Your data') }}</div>
            <div v-if="!$store.state.mobileLayout" class="p-d-flex p-ai-center p-flex-wrap">
              <div class="p-d-flex p-ai-center p-mr-3">
                <RadioButton id="form-individual-checkbox" value="Individual" v-model="customerType"/>
                <label for="form-individual-checkbox" class="radio-button-label">{{ $t('Individual') }}</label>
              </div>
              <div class="p-d-flex p-ai-center">
                <RadioButton id="form-company-checkbox" value="Company" v-model="customerType"/>
                <label for="form-company-checkbox" class="radio-button-label">{{ $t('Company') }}</label>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div v-if="$store.state.mobileLayout" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-2 p-pt-0">
              <div class="p-d-flex p-ai-center p-flex-wrap">
                <div class="p-d-flex p-ai-center p-mr-3">
                  <RadioButton id="form-individual-checkbox" value="Individual" v-model="customerType"/>
                  <label for="form-individual-checkbox" class="radio-button-label">{{ $t('Individual') }}</label>
                </div>
                <div class="p-d-flex p-ai-center">
                  <RadioButton id="form-company-checkbox" value="Company" v-model="customerType"/>
                  <label for="form-company-checkbox" class="radio-button-label">{{ $t('Company') }}</label>
                </div>
              </div>
            </div>
            <template v-if="customerType === 'Individual'">
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
                  <InputText id="form-first-name"
                             class="p-inputtext-lg price-request-form__input"
                             :class="{'p-invalid' : submitted && customerType === 'Individual' && (!firstName || firstName && firstName.length > maxInputLength)}"
                             v-model.trim="firstName"
                             autocomplete="off"/>
                  <label for="form-first-name">{{ $t('First name') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && customerType === 'Individual' && !firstName">{{ $t('Required field') }}</small>
                <small class="p-invalid" v-else-if="submitted && customerType === 'Individual' && firstName && firstName.length > maxInputLength">{{ $t('Maximum length') }}: {{ maxInputLength }}</small>
              </div>
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
                  <InputText id="form-last-name"
                             class="p-inputtext-lg price-request-form__input"
                             :class="{'p-invalid' : submitted && customerType === 'Individual' && lastName && lastName.length > maxInputLength}"
                             v-model.trim="lastName"
                             autocomplete="off"/>
                  <label for="form-last-name">{{ $t('Last name') }} <span style="font-size: 0.88rem; font-style: italic">({{ $t('Optional field') }})</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && lastName && lastName.length > maxInputLength">{{ $t('Maximum length') }}: {{ maxInputLength }}</small>
              </div>
            </template>

            <template v-else-if="customerType === 'Company'">
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
                  <InputText id="form-company-name"
                             class="p-inputtext-lg price-request-form__input"
                             :class="{'p-invalid' : submitted && customerType === 'Company' && (!companyName || companyName && companyName.length > maxInputLength)}"
                             v-model.trim="companyName"
                             autocomplete="off"/>
                  <label for="form-company-name">{{ $t('Company name') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && customerType === 'Company' && !companyName">{{ $t('Required field') }}</small>
                <small class="p-invalid" v-if="submitted && customerType === 'Company' && companyName && companyName.length > maxInputLength">{{ $t('Maximum length') }}: {{ maxInputLength }}</small>
              </div>
              <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
                <div class="p-float-label">
                  <InputText id="form-company-reg-number"
                             class="p-inputtext-lg price-request-form__input"
                             :class="{'p-invalid' : submitted && customerType === 'Company' && (!companyRegNumber || companyRegNumber && companyRegNumber.length > maxInputLength)}"
                             v-model.trim="companyRegNumber"
                             autocomplete="off"/>
                  <label for="form-company-reg-number">{{ $t('Reg. number') }}<span class="warning-color">*</span></label>
                </div>
                <small class="p-invalid" v-if="submitted && customerType === 'Company' && !companyRegNumber">{{ $t('Required field') }}</small>
                <small class="p-invalid" v-else-if="submitted && customerType === 'Company' && companyRegNumber && companyRegNumber.length > maxInputLength">{{ $t('Maximum length') }}: {{ maxInputLength }}</small>
              </div>
            </template>

            <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
              <div class="p-float-label">
                <InputText id="form-email"
                           class="p-inputtext-lg price-request-form__input"
                           :class="{'p-invalid' : submitted && (!email || (email && (!emailIsValid || email.length > maxEmailLength)))}"
                           @paste="validateEmail"
                           @input="validateEmail"
                           v-model.trim="email"
                           type="email"
                           autocomplete="off"/>
                <label for="form-email">{{ $t('Email') }}<span class="warning-color">*</span></label>
              </div>
              <small class="p-invalid" v-if="submitted && !email">{{ $t('Required field') }}</small>
              <small class="p-invalid" v-else-if="submitted && !emailIsValid">{{ $t('Value is not a valid email address') }}</small>
              <small class="p-invalid" v-else-if="submitted && email.length > maxEmailLength">{{ $t('Maximum length') }}: {{ maxEmailLength }}</small>
            </div>
            <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-fluid">
              <div class="phone-inputs-wrapper">
                <div class="p-float-label">
                  <CustomDropdown id="form-phone-code"
                                  class="phone-code p-inputtext-lg price-request-form__input"
                                  :class="{'p-invalid' : submitted && !selectedPhoneCode, 'phone-code--short' : $store.state.mobileLayout}"
                                  v-model="selectedPhoneCode"
                                  :isColorCustom="true"
                                  :noRightBorder="true"
                                  :options="phoneCodesArray"
                                  :filter="true"
                                  optionLabel="country"
                                  :filterFields="['country','value']"
                                  :showClear="false"
                                  autocomplete="new-password">
                    <template #value="slotProps">
                      <div class="country-item country-item-value" v-if="slotProps.value">
                        <template v-if="slotProps.value.flagCode">
                          <span :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                          <span>{{ slotProps.value.value }}</span>
                        </template>
                        <template v-else>
                          <span>{{ slotProps.value.value }}</span>
                        </template>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                          <span v-if="slotProps.option.flagCode" :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                          <span >{{ slotProps.option.country }}</span>
                        </div>
                        <div style="font-weight: 500">{{ slotProps.option.value }}</div>
                      </div>
                    </template>
                  </CustomDropdown>
                </div>
<!--                <div class="p-float-label p-input-icon-right" style="width: 100%;">-->
                <div class="p-float-label" style="width: 100%;">
<!--                  <i class="pi pi-phone" />-->
                  <InputText id="form-phone-number"
                            class=" phone-number p-inputtext-lg price-request-form__input"
                            :class="{'p-invalid' : submitted && (!phoneNumber || (phoneNumber && phoneNumber.length > maxPhoneNumberLength))}"
                            v-model="phoneNumber"
                            type="number"
                            @keypress="numberInputOnKeyPress"
                            @paste="numberOnPaste"
                            autocomplete="new-password"/>
                  <label for="form-phone-number">{{ $t('Phone') }}<span class="warning-color">*</span></label>
                </div>
              </div>
              <small style="position: relative; left: 120px" class="p-invalid" v-if="submitted && !phoneNumber">{{ $t('Required field') }}</small>
              <small style="position: relative; left: 120px" class="p-invalid" v-else-if="submitted && phoneNumber.length > maxPhoneNumberLength">{{ $t('Maximum length') }}: {{ maxPhoneNumberLength }}</small>
              <small style="position: relative; left: 120px" class="p-invalid" v-else-if="submitted && phoneNumber.length < minPhoneNumberLength">{{ $t('Minimum length') }}: {{ minPhoneNumberLength }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="p-fluid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 fieldset" :class="{'p-p-3' : !$store.state.mobileLayout}">
          <div class="price-request-form__legend fieldset-header">{{ $t('Desired works / parts') }}</div>
          <div class="p-grid">
            <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-0">
              <div class="p-float-label p-input-icon-right">
                <i class="ti-comment-alt"></i>
                <Textarea id="form-request-description"
                          class="p-inputtext-lg price-request-form__input textarea-vertical-resize"
                          :class="{'p-invalid' : (submitted && !description) || computeDescriptionRemainingLength < 0}"
                          v-model.trim="description"
                          :rows="$store.state.mobileLayout ? 5 : 3"
                          :autoResize="true"
                          autocomplete="off"/>
                <label for="form-request-description">{{ $t('Description') }}<span class="warning-color">*</span></label>
              </div>
              <div style="font-size: 0.9rem" :class="{'warning-color': computeDescriptionRemainingLength < 0}">{{ $t('Characters left') }}: <span>{{ computeDescriptionRemainingLength }}</span></div>
              <small class="p-invalid" v-if="submitted && !description">{{ $t('Required field') }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="parts-by-block" :class="{'warning-border' : submitted && !partsBy}">
        <div class="parts-by-block-buttons">
          <div class="p-d-flex p-ai-center p-p-2">
            <RadioButton id="form-workshop-parts-checkbox" value="Workshop" v-model="partsBy"/>
            <label for="form-workshop-parts-checkbox" class="radio-button-label">{{ $t('Workshop parts') }}</label>
          </div>
          <div class="p-d-flex p-ai-center p-p-2">
            <RadioButton id="form-customer-parts-checkbox" value="Customer" v-model="partsBy"/>
            <label for="form-customer-parts-checkbox" class="radio-button-label">{{ $t('Customer parts') }}</label>
          </div>
        </div>
        <small class="p-invalid" v-if="submitted && !partsBy">{{ $t('Required field') }}</small>
      </div>

      <div class="agree-checkbox-block" :class="{ 'p-flex-column' : $store.state.mobileLayout }">
<!--            <InputSwitch id="form-agree-inputswitch" class="p-mr-2" v-model="privatePolicyIsAccepted"/>-->
        <Checkbox class="p-mr-1" id="form-agree-inputswitch" :binary="true" v-model="privatePolicyIsAccepted"/>
        <div>
          <label for="form-agree-inputswitch" >
            <div class="radio-button-label pointer">
              {{ $t('privacyPolicyCheckboxText') }}
              <a class="form-custom-link" :href="privacyPolicyLink" target="_blank">{{ $t('privacyPolicyCheckboxLink') }}</a>
            </div>
          </label>
        </div>
      </div>
      <div class="submit-btn-block">
        <Button :disabled="!privatePolicyIsAccepted || disableSendButton" @click="sendPriceRequest" :label="$t('Send request')" class="p-button-lg p-button-raised"></Button>
      </div>

<!--      <div class="form-footer">-->
<!--        <div><span class="warning-color">* </span>{{ $t('Fields are required') }}</div>-->
<!--            <div>Powered by <a href="https://virtualgarage.app" target="_blank">VirtualGarage.app</a></div>-->
<!--      </div>-->
<!--        </div>-->
    </div>

    <div v-else-if="showOfferCreatedBlock" class="p-formgrid p-grid">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12" style="text-align: center; margin: 4vh auto">
        <i class="pi pi-check-circle" style="font-size: 120px; color: #689F38"></i>
        <h4 class="p-pt-3">{{ $t('priceRequestSentThankYouText') }}</h4>
        <p class="p-pt-3 p-pb-3">{{ $t('priceRequestSentWeReceivedText') }}</p>
        <Button :label="$t('Go back')" @click="closeOfferCreatedBlock" class="p-button-lg p-button-raised"></Button>
      </div>
    </div>
  </div>
</template>


<!--<iframe src="http://localhost:8081/price-request/ru" id="price-request-form-iframe" loading="lazy" allowtransparency seamless></iframe>-->
<!--<style>#price-request-form-iframe {background-color: transparent!important;width: 100%;height: 720px;} @media (max-width: 852px) {#price-request-form-iframe {height: 1190px;}}</style>-->

<script>
// import LoginLanguageSelector from "@/components/LoginLanguageSelector";
import phoneCodes from '@/translations/phoneCodes'
import settings from "@/settings";
// import InputSwitch from "@/pages/price_request/components/InputSwitch";
import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";
import generateMixins from "@/mixins/generateMixins";
import webSocketMixins from "@/mixins/webSocketMixins";

export default {
  mixins: [ httpMixins, generateMixins, webSocketMixins ],
  // components: { InputSwitch, CustomRadioButton },
  data() {
    return {
      // privacyPolicyLink: `https://carvex.ee/${this.$i18n.locale ?? settings.defaultLanguage}/privaatsuspoliitika`,
      privacyPolicyLink: 'https://carvex.ee/et/privaatsuspoliitika',
      dataIsSending: false,
      makesDataIsLoading: false,
      modelsDataIsLoading: false,
      clearModelSearchData: false,
      maxPlateNumberLength: 10,
      maxEmailLength: 45,
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 14,
      maxDescriptionLength: 1600,
      maxInputLength: 35,
      showOfferCreatedBlock: false,
      disableSendButton: false,
      minCarYear: new Date().getFullYear() - 60,
      maxCarYear: new Date().getFullYear(),
      phoneCodesArray: [],
      emailIsValid: false,
      partsBy: null,
      selectedMake: null,
      selectedModel: null,
      plateNumber: null,
      year: null,
      year2: null,
      vinCode: null,
      customerType: 'Individual',
      firstName: null,
      lastName: null,
      companyName: null,
      companyRegNumber: null,
      email: null,
      defaultPhoneCode: null,
      selectedPhoneCode: null,
      phoneNumber: null,
      description: null,
      formDataRawText: null,
      submitted: false,
      privatePolicyIsAccepted: false,
      languageMenuActive: false,
      languageMenuButtonClick: false,
      carMakes: null,
      carModels: null,
      // previouslySelectedMake: null
    }
  },
  mounted() {
    this.$i18n.locale = this.$route.params.lang ?? settings.defaultLanguage
    this.createPhoneCodesArray()
    this.findDefaultPhoneCode()
    this.getCarMakes()
    // this.colorizeTheme()
  },
  methods: {
    checkCarYear() {
      if (!this.year) return
      if (this.year < this.minCarYear) {
        this.year = this.minCarYear
      } else if (this.year > this.maxCarYear) {
        this.year = this.maxCarYear
      }
    },
    // colorizeTheme() {
    //   const objects = document.getElementsByClassName('background-theme-color');
    //   // objects[0].style.backgroundColor = 'red'
    //   // objects[0].classList.add('test22')
    //   // objects[0].classList.remove('background-theme-color');
    //
    //
    //   for (let obj of objects) {
    //     obj.classList.add('test22')
    //
    //     obj.classList.remove('background-theme-color');
    //     console.log(obj.classList)
    //
    //   }
    //   // console.log(objects[0].classList)
    //   // setTimeout(() => , 100)
    //
    // },
    changePlateNumber(event) {
      let value = event.target.value
      if (value) {
        value = value.toUpperCase()
      }
      this.plateNumber = this.generateRawCode(value)
    },
    changeVin(event) {
      let value = event.target.value
      if (value) {
        value = value.toUpperCase()
      }
      this.vinCode = this.generateRawCode(value)
    },
    createFormDataRawText() {
      const formDataRawText = {
        car_make: this.selectedMake ? this.selectedMake.name : null,
        car_model: this.selectedModel ? this.selectedModel.name : null,
        year: this.year,
        plate_number: this.plateNumber,
        // vin: 1,
        customer_type: this.customerType === 'Individual' ? 1 : (this.customerType === 'Company' ? 2 : null),
        // first_name: 1,
        // last_name: 1,
        // company_name: 1,
        // reg_number: 1,
        phone_code: this.selectedPhoneCode ? this.selectedPhoneCode.value : null,
        phone_number: this.phoneNumber,
        email: this.email,
        parts_by: this.partsBy === 'Workshop' ? 1 : (this.partsBy === 'Customer' ? 2 : null),
        description: this.description,
        lang: this.$i18n.locale,
      }
      if (this.vin) {
        formDataRawText.vin = this.vin
      }
      if (this.customerType === 'Individual') {
        formDataRawText.first_name = this.firstName
        if (this.lastName)
        formDataRawText.last_name = this.lastName
      }

      if (this.customerType === 'Company') {
        formDataRawText.company_name = this.companyName
        if (this.companyRegNumber)
          formDataRawText.reg_number = this.companyRegNumber
      }
      
      this.formDataRawTextJSON = JSON.stringify(formDataRawText)
    },
    closeOfferCreatedBlock() {
      this.showOfferCreatedBlock = false
      this.submitted = false
      this.selectedMake = null
      this.selectedModel = null
      this.plateNumber = null
      this.year = null
      this.vinCode = null
      this.customerType = 'Individual'
      this.firstName = null
      this.lastName = null
      this.companyName = null
      this.companyRegNumber = null
      this.emailIsValid = false
      this.email = null
      this.selectedPhoneCode = this.defaultPhoneCode ?? null
      this.phoneNumber = null
      this.partsBy = null
      this.description = null
      this.privatePolicyIsAccepted = false
      this.carModels = null
    },
    async getCarMakes() {
      this.makesDataIsLoading = true
      try {
        const { status, data } = await httpClient('site/get-car-makes?sort=name')
        if (status === 200 && data) {
          this.carMakes = data
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.makesDataIsLoading = false
      }
    },
    async getCarModels() {
      if (!this.selectedMake) return false
      this.modelsDataIsLoading = true
      try {
        const { status, data } = await httpClient('site/get-car-models?sort=name&makeId=' + this.selectedMake.id)
        if (status === 200 && data) {
          this.carModels = data.map(make => ({ id: make.id, name: make.name }))
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.modelsDataIsLoading = false
      }
    },
    changeCarMake() {
      if (!this.selectedMake) {
        this.selectedModel = null
        this.carModels = null
      } else {
        this.getCarModels()
        this.clearModelSearchData = true
        this.selectedModel = null
      }
    },
    plateNumberOnInput(event) {
      if (event.target.value) {
        this.plateNumber = event.target.value.toUpperCase()
      }
    },
    plateNumberOnKeyPress(event) {
      if (this.isLetter(event.key) || (event.charCode >= 48 && event.charCode <= 57)) {
        return
      } else {
        event.preventDefault()
      }
    },
    isLetter(c) {
      return c.toLowerCase() != c.toUpperCase();
    },
    yearOnInput() {
      const year = this.year
      if (!year) return false
      if (year > this.maxCarYear) {
        setTimeout(() => this.year = this.maxCarYear, 100)
      }
    },
    numberOnPaste(event) {
      const text = event.clipboardData.getData('text/plain')

      let isTextContainsSpace = false
      const splittedNumber = text.split('')
      splittedNumber?.forEach(n => {
        if (n === ' ') {
          isTextContainsSpace = true
        }
      })

      if (text.replace(/\s/g, '').length === 0 || isNaN(text) || isTextContainsSpace) {
        event.preventDefault()
        return false
      }
    },
    numberInputOnKeyPress(event) {
      const res = event.charCode >= 48 && event.charCode <= 57
      if (!res) {
        event.preventDefault()
        return false
      }
    },
    // phoneNumberOnInput() {
      // const phoneNUmberLength = this.phoneNumber ? this.phoneNumber.length : 0
      // console.log(phoneNUmberLength)
    // },
    validateInputs() {
      if (!this.selectedMake || !this.selectedModel ||
          !this.plateNumber || (this.plateNumber && this.plateNumber.length > this.maxPlateNumberLength) ||
          !this.year || (this.year && (this.year < this.minCarYear || this.year > this.maxCarYear)) ||
          this.customerType === 'Individual' && !this.firstName ||
          this.customerType === 'Individual' && this.firstName && (this.firstName.length > this.maxInputLength) ||
          this.customerType === 'Individual' && this.lastName && (this.lastName.length > this.maxInputLength) ||
          this.customerType === 'Company' && !this.companyName || (this.companyName && this.companyName.length > this.maxInputLength) ||
          this.customerType === 'Company' &&  !this.companyRegNumber || (this.companyRegNumber && this.companyRegNumber.length > this.maxInputLength) ||
          !this.email || (this.email && (!this.emailIsValid || this.email.length > this.maxEmailLength)) ||
          !this.phoneNumber || (this.phoneNumber && (this.phoneNumber.length > this.maxPhoneNumberLength || this.phoneNumber.length < this.minPhoneNumberLength)) ||
          !this.description || (this.description && this.description.length > this.maxDescriptionLength) ||
          !this.partsBy ||
          !this.privatePolicyIsAccepted) {
        // console.log('not validated')
        return false
      } else {
        // console.log('ok validated')
        return true
      }
    },
    async sendPriceRequest() {
      this.submitted = true
      if (!this.validateInputs()) return false

      this.createFormDataRawText()

      // let language = this.$route.params.lang ?? settings.defaultLanguage
      let language = this.$i18n.locale ?? settings.defaultLanguage

      let flagCode = settings.languages.find(lang => lang.value === language)?.flagCode

      if (!language || !flagCode) {
        const filteredDefaultLanguage = settings.languages.find(lang => lang.value === settings.defaultLanguage)
        const defaultLanguage = filteredDefaultLanguage?.value ?? null
        const defaultFlagCode = filteredDefaultLanguage?.flagCode ?? null

        if (defaultLanguage && defaultFlagCode) {
          language = defaultLanguage
          flagCode = defaultFlagCode
        }
      }

      this.disableSendButton = true
      this.dataIsSending = true

      const offerData = {
        car_make_id: this.selectedMake ? this.selectedMake.id : null,
        car_model_id: this.selectedModel ? this.selectedModel.id : null,
        car_plate_number: this.plateNumber ? this.removeSpaces(this.plateNumber) : null,
        car_year: this.year ?? null,
        car_vin_code: this.vinCode ?? null,
        customer_type: this.customerType === 'Individual' ? 1 : 2,
        first_name: this.customerType === 'Individual' && this.firstName ? this.firstName : null,
        last_name: this.customerType === 'Individual' && this.lastName ? this.lastName : null,
        company_name: this.customerType === 'Company' && this.companyName ? this.companyName : null,
        company_reg_number: this.customerType === 'Company' && this.companyRegNumber ? this.companyRegNumber : null,
        email: this.email ?? null,
        phone_code: this.selectedPhoneCode ? this.selectedPhoneCode.value : null,
        phone_number: this.phoneNumber,
        full_phone_number: this.selectedPhoneCode && this.phoneNumber ? String(this.selectedPhoneCode.value) + String(this.phoneNumber) : null,
        description: this.description,
        parts_by: this.partsBy ? (this.partsBy === 'Workshop' ? 1 : 2) : null,
        language: language,
        flag_code: flagCode,
        form_data_raw_text_json: this.formDataRawTextJSON
      }

      try {
        const { status, data } = await httpClient.post(`site/create-request`, offerData)
        if (status === 201 && data?.success) {
          this.showOfferCreatedBlock = true
          // this.sendInstantSocket('createOffer', data.id)
          this.sendPriceRequestCreatedSocket()
        } else if (data?.error) {
          // this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: data.error.detail, life: settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
        // this.$toast.add({severity:'error', summary: this.$t('Error'), life: settings.toastLife});
        // this.showError(err)
      } finally {
        this.disableSendButton = false
        this.dataIsSending = false
      }
    },
    createPhoneCodesArray() {
      this.phoneCodesArray = phoneCodes.map(code => {
        // if (code[2] === settings.phoneCodes.default) {
        //   this.defaultPhoneCode = {
        //     value: code[2],
        //     country: code[0],
        //     flagCode: code[1]
        //   }
        //   this.selectedPhoneCode = {...this.defaultPhoneCode}
        // }

        return {
          value: code[2],
          country: code[0],
          flagCode: code[1]
        }
      })
    },
    findDefaultPhoneCode() {
      const defaultPhoneCode = phoneCodes.find((code) => code[2] === settings.phoneCodes.default)
      if (defaultPhoneCode) {
        this.defaultPhoneCode = {
          value: defaultPhoneCode[2],
          country: defaultPhoneCode[0],
          flagCode: defaultPhoneCode[1]
        }
        this.selectedPhoneCode = {...this.defaultPhoneCode}
      }
    },
    validateEmail() {
      const email = this.email
      if (!email) return false
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!isCompany) {
      this.emailIsValid = re.test(email)
      // } else {
      //   this.companyEmailIsValid = re.test(email)
      // }
    },
    onLanguageMenuButtonClick() {
      this.languageMenuButtonClick = true
      this.languageMenuActive = !this.languageMenuActive
    },
    onDocumentClick() {
      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }
      this.languageMenuButtonClick = false
    },
  },
  computed: {
    computeDescriptionRemainingLength() {
      // if (!this.description) return 0
      return this.maxDescriptionLength - (this.description?.length ?? 0)
    }
  }
}
</script>

<style lang="scss" scoped>
//$themeColor: #2ec700;
//$themeColor: #fc0;
//$backgroundColor: #fff;
//$lightTransparentThemeColor: transparentize($themeColor, 0.6);
//$darkThemeColor: darken($themeColor, 5%);

//.main-bg-color {
//  background: #2ec700;
//}
//
//.background-theme-color {
//  background-color: red!important;
//}

.test22 {
  background-color: red!important;
}

.price-request-form-body {
  font-size: 15px;
  .price-request-form__legend {
    font-size: 1.35rem;
    //font-weight: 00;
    //padding: 12px 0;
    //border-radius: 3px;
    //background-color: transparentize(#999, 0.95);
  }

  .fieldset {
    border-radius: 3px;
    margin-bottom: 26px;
    width: 100% !important;
    //padding: 16px;
    background: #fafaf8;
    //padding: 15px 20px;
  }

  .fieldset-header {
    display: flex;
    align-items: center;
    height: 36px;
    margin-bottom: 22px;
    //padding: 24px 12px;
    border-radius: 3px;
  }

  .price-request-form__input {
    width: 100%;
  }

  .price-request-form__input-number {
    width: 100%;
  }

  .agree-checkbox-block,
  .submit-btn-block {
    display: flex;
    align-items: center;
    justify-content: center;
    //flex-wrap: wrap;
    text-align: center;
    //padding: 20px 0;
    margin: 28px auto;
    border-radius: 3px;
  }

  .parts-by-block {
    //margin-top: -10px;
    padding: 10px 6px;
    border-radius: 3px;
    //background-color: transparentize(#999, 0.95);
    background-color: #fafaf8;
    border: 1px solid transparent;
    .parts-by-block-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .radio-button-label {
    padding: 6px;
    margin-left: 0;
    cursor: pointer;
  }

  .form-footer {
    margin-top: 20px;
    //text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .phone-code {
    //width: 120px;
    width: 110px;
  }
  .phone-code--short {
    width: 110px;
  }
}
.grid {
  display: grid;
  grid-gap: 26px;
  grid-template-columns: 1fr 1fr;
}
//.price-request-form__legend {
//  font-size: 1.35rem;
//  //font-weight: 00;
//  //padding: 12px 0;
//  //border-radius: 3px;
//  //background-color: transparentize(#999, 0.95);
//}
//
//.fieldset {
//  border-radius: 3px;
//  margin-bottom: 26px;
//  padding: 16px;
//  background: #fafaf8;
//  //padding: 15px 20px;
//}
//
//.fieldset-header {
//  display: flex;
//  align-items: center;
//  height: 36px;
//  margin-bottom: 18px;
//  //padding: 24px 12px;
//  border-radius: 3px;
//}

//.price-request-form__input {
//  width: 100%;
//}
//
//.price-request-form__input-number {
//  width: 100%;
//}
//
//.agree-checkbox-block,
//.submit-btn-block {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  //flex-wrap: wrap;
//  text-align: left;
//  //padding: 20px 0;
//  margin: 28px auto;
//  border-radius: 3px;
//}
//
//.parts-by-block {
//  //margin-top: -10px;
//  padding: 10px 6px;
//  border-radius: 3px;
//  //background-color: transparentize(#999, 0.95);
//  background-color: #fafaf8;
//  border: 1px solid transparent;
//  .parts-by-block-buttons {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    flex-wrap: wrap;
//  }
//}
//
//.radio-button-label {
//  padding: 6px;
//  margin-left: 0;
//  cursor: pointer;
//}
//
//.form-footer {
//  margin-top: 20px;
//  //text-align: right;
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//}
//.phone-code {
//  //width: 120px;
//  width: 110px;
//}
//.phone-code--short {
//  width: 110px;
//}

.no-right-border {
  border-right: none!important;
}

</style>